import { StageStatus } from '@/enums/StageStatus';
import { LotStatus } from '@/enums/LotStatus';
import { ContactStatus } from '@/enums/ContactStatus';
import { StatusFiltersLabels } from '@/constants/statusFilters';
import { EoiStatus } from '@/enums/EoiStatus';
import { LeadStatus } from '@/enums/LeadStatus';
import { InvoiceStatus } from '@/enums/InvoiceStatus';
import { ListingStatus } from '@/enums/ListingStatus';
import InitialHoldStatus from '@/enums/initialHoldStatus';

export function getStageStatusClass(status: StageStatus): string {
  if (status === StageStatus.SOLD_OUT) {
    return 'warning';
  }
  if (status === StageStatus.NOW_SELLING) {
    return 'success';
  }
  return '';
}

export function getLotStatusClass(status: LotStatus): string {
  switch (status) {
    case LotStatus.NOT_RELEASED:
      return 'gray';
    case LotStatus.SETTLED:
      return 'warning';
    case LotStatus.UNCONDITIONAL:
      return 'primary';
    case LotStatus.EOI:
    case LotStatus.HOLD:
      return 'light-blue';
    case LotStatus.CONTRACT_EXECUTED:
      return 'yellow';
    case LotStatus.AVAILABLE:
      return 'success';
    case LotStatus.OPTION:
      return 'orange';
    case LotStatus.OFFLINE:
      return 'gray-middle';
    case LotStatus.PRE_CONTRACT:
      return 'blue';
    default:
      return '';
  }
}

export function getLotPreviousStatus(status: LotStatus) {
  if (status === LotStatus.OPTION) {
    return LotStatus.AVAILABLE;
  }
  const statuses = [
    LotStatus.NOT_RELEASED,
    LotStatus.AVAILABLE,
    LotStatus.HOLD,
    LotStatus.EOI,
    LotStatus.CONTRACT_EXECUTED,
    LotStatus.UNCONDITIONAL,
    LotStatus.SETTLED,
  ];
  const index = statuses.indexOf(status);
  return statuses[index - 1];
}

export function getLotCustomerMapStatusClass(status: LotStatus): string {
  const warningStatusList = [
    LotStatus.SETTLED,
    LotStatus.OPTION,
    LotStatus.OFFLINE,
    LotStatus.UNCONDITIONAL,
    LotStatus.SOLD
  ];
  if (warningStatusList.includes(status)) {
    return "warning";
  }
  if (status === LotStatus.EOI || status === LotStatus.HOLD) {
    return "light-blue";
  }
  if (
    status === LotStatus.CONTRACT_EXECUTED ||
    status === LotStatus.UNDER_CONTRACT
  ) {
    return "yellow";
  }
  if (status === LotStatus.AVAILABLE) {
    return 'success';
  }
  return '';
}

export function getContactStatusClass(status: ContactStatus): string {
  if (status.toLocaleLowerCase() === ContactStatus.BUYER.toLocaleLowerCase()) {
    return 'primary';
  }
  if (status.toLocaleLowerCase() === ContactStatus.PROSPECT.toLocaleLowerCase()) {
    return 'light-blue';
  }
  return '';
}

export function getLeadStatusClass(status: LeadStatus): string {
  if (status === LeadStatus.NEW) {
    return 'success';
  }
  if (status === LeadStatus.CONTACTED) {
    return 'light-blue';
  }
  if (status === LeadStatus.QUALIFIED) {
    return 'yellow';
  }
  if (status === LeadStatus.UNQUALIFIED) {
    return 'warning';
  }
  return '';
}

export function getStatusClass(status: string): string {
  if (status === StatusFiltersLabels.AVAILABLE) {
    return 'primary';
  }
  return '';
}

export function getEoiStatusClass(status: EoiStatus): string {
  switch (status) {
    case EoiStatus.PENDING:
      return 'primary';
    case EoiStatus.APPROVED:
      return 'success';
    case EoiStatus.DECLINED:
      return '';
    case EoiStatus.WAITING_FOR_APPROVED:
    case EoiStatus.WAITING_FOR_DECLINED:
      return 'light-blue';
    case EoiStatus.WITHDRAWN:
      return 'yellow';
    case EoiStatus.REFUNDED:
      return 'orange';
    case EoiStatus.REFUND_DECLINED:
    case EoiStatus.TRANSACTION_ERROR:
      return 'warning';
    default:
      return '';
  }
}

export function getInvoiceStatusClass(status: InvoiceStatus): string {
  if (status === InvoiceStatus.IN_PROGRESS) {
    return 'light-blue';
  }
  if (status === InvoiceStatus.FUNDS_CLEARED) {
    return 'success';
  }
  if (status === InvoiceStatus.ERROR) {
    return 'warning';
  }
  if (status === InvoiceStatus.REFUNDED) {
    return 'gray';
  }
  return '';
}

export function getListingStatusClass(status: string): string {
  if (status === ListingStatus.PUBLISHED) {
    return 'light-blue';
  }
  if (status === ListingStatus.DEACTIVATED) {
    return 'gray';
  }
  if (status === ListingStatus.SOLD) {
    return 'warning';
  }
  if (status === ListingStatus.DRAFT) {
    return 'yellow';
  }

  if (status === ListingStatus.PROCESSING) {
    return 'primary';
  }
  return '';
}
export function getInitialHoldStatusClass(status: string): string {
  if (status === InitialHoldStatus.PENDING) {
    return "primary";
  }
  if (status === InitialHoldStatus.APPROVED) {
    return "success";
  }
  if (status === InitialHoldStatus.DECLINED) {
    return "warning";
  }
  if (status === InitialHoldStatus.EXPIRED) {
    return "light-blue";
  }
  if (status === InitialHoldStatus.CLOSED) {
    return "gray";
  }
  if (status === InitialHoldStatus.CANCELLED) {
    return "warning";
  }
  return "";
}
