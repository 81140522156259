import { LeadStatus } from "@/enums/LeadStatus";

export enum StatusFiltersValues {
  ALL = "all",
  ANY = "any",
  HOLD = "hold",
  AVAILABLE = "available",
  EOI = "eoi",
  CONTRACT_EXECUTED = "contract-executed",
  UNCONDITIONAL = "unconditional",
  SETTLED = "settled",
  NOT_RELEASED = "not-released",
  SOLD_OUT = "sold-out",
  OPTION = "option",
  OFFLINE = "Offline",
  NOW_SELLING = "now-selling",
  BUYER = "buyer",
  PROSPECT = "prospect",
  NEW = "New",
}

export enum StatusFiltersLabels {
  ALL = "All",
  ANY = "Any",
  HOLD = "Hold",
  AVAILABLE = "Available",
  EOI = "EOI",
  CONTRACT_EXECUTED = "Contract Executed",
  UNCONDITIONAL = "Unconditional",
  SETTLED = "Settled",
  NOT_RELEASED = "Not Released",
  SOLD_OUT = "Sold Out",
  OPTION = "Option",
  OFFLINE = "Offline",
  NOW_SELLING = "Now Selling",
  BUYER = "Buyer",
  PROSPECT = "Prospect",
}

export enum StatusListingFiltersLabels {
  ANY = "Any",
  PUBLISHED = "Published",
  SOLD = "Sold",
  DEACTIVATED = "Deactivated",
  DRAFT = "Draft",
  PROCESSING = "Processing",
}

export enum StatusListingFiltersValues {
  ANY = "any",
  PUBLISHED = "published",
  SOLD = "sold",
  DEACTIVATED = "deactivated",
  DRAFT = "draft",
  PROCESSING = "processing",
}

export enum HoldStatusFilterValues {
  ANY = "Any",
  PENDING = "Pending",
  APPROVED = "Approved",
  DECLINED = "Declined",
  EXPIRED = "Expired",
  CANCELLED = "Cancelled",
  CLOSED = "Progressed",
}

export enum HoldStatusFilterLabel {
  ANY = "Any",
  PENDING_OR_ACTIVE = "Pending or Active",
  PENDING = "Pending",
  APPROVED = "Active",
  DECLINED = "Declined",
  EXPIRED = "Expired",
  CANCELLED = "Cancelled",
  CLOSED = "Closed",
}

export enum EoiStatusFilterValues {
  ANY = "any",
  PENDING = "pending",
  APPROVED = "approved",
  DECLINED = "declined",
  TEMP = "temp",
  DRAFT = "draft",
  PENDING_FOR_BILLING = "pending for billing",
  TERMINATED = "terminated",
  TRANSACTION_ERROR = "transaction_error",
  WAITING_FOR_APPROVED = "waitingForApproved", 
  WAITING_FOR_DECLINED = "waitingForDeclined",
  WITHDRAWN = "withdrawn",
  REFUNDED = "refunded",
  REFUND_DECLINED = "refundDeclined", 
  WAITING_FOR_PENDING = "waitingForPending",
}

export enum EoiStatusFilterLabel {
  ANY = "Any",
  PENDING_OR_APPROVED = "Pending or Approved",
  PENDING = "Pending",
  APPROVED = "Approved",
  DECLINED = "Declined",
  TEMP = "Temp",
  DRAFT = "Draft",
  PENDING_FOR_BILLING = "Pending for billing",
  TERMINATED = "Terminated",
  TRANSACTION_ERROR = "Transaction Error",
  WAITING_FOR_APPROVED = "Waiting for approved", 
  WAITING_FOR_DECLINED = "Waiting for declined",
  WITHDRAWN = "Withdrawn",
  REFUNDED = "Refunded",
  REFUND_DECLINED = "Refund declined", 
  WAITING_FOR_PENDING = "Waiting for pending",
}

export const statusFilters = [
  {
    value: StatusFiltersValues.ANY,
    label: StatusFiltersLabels.ANY,
  },
  {
    value: StatusFiltersValues.HOLD,
    label: StatusFiltersLabels.HOLD,
  },
  {
    value: StatusFiltersValues.NOT_RELEASED,
    label: StatusFiltersLabels.NOT_RELEASED,
  },
  {
    value: StatusFiltersValues.AVAILABLE,
    label: StatusFiltersLabels.AVAILABLE,
  },
  {
    value: StatusFiltersValues.EOI,
    label: StatusFiltersLabels.EOI,
  },
  {
    value: StatusFiltersValues.CONTRACT_EXECUTED,
    label: StatusFiltersLabels.CONTRACT_EXECUTED,
  },
  {
    value: StatusFiltersValues.UNCONDITIONAL,
    label: StatusFiltersLabels.UNCONDITIONAL,
  },
  {
    value: StatusFiltersValues.OPTION,
    label: StatusFiltersLabels.OPTION,
  },
  {
    value: StatusFiltersValues.OFFLINE,
    label: StatusFiltersLabels.OFFLINE,
  },
  {
    value: StatusFiltersValues.SETTLED,
    label: StatusFiltersLabels.SETTLED,
  },
];

export const stagesStatuses = [
  {
    value: StatusFiltersValues.ANY,
    label: StatusFiltersLabels.ANY,
  },
  {
    value: StatusFiltersValues.NOT_RELEASED,
    label: StatusFiltersLabels.NOT_RELEASED,
  },
  {
    value: StatusFiltersValues.NOW_SELLING,
    label: StatusFiltersLabels.NOW_SELLING,
  },
  {
    value: StatusFiltersValues.SOLD_OUT,
    label: StatusFiltersLabels.SOLD_OUT,
  },
];

export const contactStatuses = [
  {
    value: StatusFiltersValues.ANY,
    label: StatusFiltersLabels.ANY,
  },
  {
    value: StatusFiltersValues.BUYER,
    label: StatusFiltersLabels.BUYER,
  },
  {
    value: StatusFiltersValues.PROSPECT,
    label: StatusFiltersLabels.PROSPECT,
  },
];
export const leadStatuses = [
  {
    value: StatusFiltersValues.ANY,
    label: StatusFiltersLabels.ANY,
  },
  {
    value: LeadStatus.NEW,
    label: LeadStatus.NEW,
  },
  {
    value: LeadStatus.CONTACTED,
    label: LeadStatus.CONTACTED,
  },
  {
    value: LeadStatus.QUALIFIED,
    label: LeadStatus.QUALIFIED,
  },
  {
    value: LeadStatus.UNQUALIFIED,
    label: LeadStatus.UNQUALIFIED,
  },
  {
    value: LeadStatus.COLD,
    label: LeadStatus.COLD,
  },
];
export const listingstatuses = [
  {
    value: StatusListingFiltersValues.ANY,
    label: StatusListingFiltersLabels.ANY,
  },
  {
    value: StatusListingFiltersValues.PUBLISHED,
    label: StatusListingFiltersLabels.PUBLISHED,
  },
  {
    value: StatusListingFiltersValues.SOLD,
    label: StatusListingFiltersLabels.SOLD,
  },
  {
    value: StatusListingFiltersValues.DEACTIVATED,
    label: StatusListingFiltersLabels.DEACTIVATED,
  },
  {
    value: StatusListingFiltersValues.DRAFT,
    label: StatusListingFiltersLabels.DRAFT,
  },
  {
    value: StatusListingFiltersValues.PROCESSING,
    label: StatusListingFiltersLabels.PROCESSING,
  },
];

export const holdStatuses = [
  {
    value: [HoldStatusFilterValues.PENDING, HoldStatusFilterValues.APPROVED],
    label: HoldStatusFilterLabel.PENDING_OR_ACTIVE,
  },
  {
    value: [],
    label: HoldStatusFilterLabel.ANY,
  },
  {
    value: HoldStatusFilterValues.PENDING,
    label: HoldStatusFilterLabel.PENDING,
  },
  {
    value: HoldStatusFilterValues.APPROVED,
    label: HoldStatusFilterLabel.APPROVED,
  },
  {
    value: HoldStatusFilterValues.EXPIRED,
    label: HoldStatusFilterLabel.EXPIRED,
  },
  {
    value: HoldStatusFilterValues.CLOSED,
    label: HoldStatusFilterLabel.CLOSED,
  },
  {
    value: HoldStatusFilterValues.DECLINED,
    label: HoldStatusFilterLabel.DECLINED,
  },
];

export const eoiStatuses = [
  {
    value: [EoiStatusFilterValues.PENDING, EoiStatusFilterValues.APPROVED],
    label: EoiStatusFilterLabel.PENDING_OR_APPROVED,
  },
  {
    value: [
      EoiStatusFilterValues.PENDING,
      EoiStatusFilterValues.APPROVED,
      EoiStatusFilterValues.DECLINED,
      EoiStatusFilterValues.WITHDRAWN,
    ],
    label: EoiStatusFilterLabel.ANY,
  },
  {
    value: EoiStatusFilterValues.PENDING,
    label: EoiStatusFilterLabel.PENDING,
  },
  {
    value: EoiStatusFilterValues.APPROVED,
    label: EoiStatusFilterLabel.APPROVED,
  },
  {
    value: EoiStatusFilterValues.DECLINED,
    label: EoiStatusFilterLabel.DECLINED,
  },
  {
    value: EoiStatusFilterValues.WITHDRAWN,
    label: EoiStatusFilterLabel.WITHDRAWN,
  },
];

